import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import {
  SiteSetting,
  SiteSettingService
} from 'src/app/services/site-setting.service';

@Component({
  selector: 'app-layout',
  template: `<div class="{{ siteNameClass }}">
    <router-outlet></router-outlet>
  </div>`,
  styles: []
})
export class AppLayoutComponent implements OnInit {
  siteNameClass: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private siteSettingsService: SiteSettingService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.pipe(take(1)).subscribe({
      next: (response: { siteSettings: SiteSetting }) => {
        this.siteSettingsService.save(response.siteSettings);
        this.siteNameClass = this.siteSettingsService.siteSetting.site.name;
      },
      // Only one of the following will be called
      error: (error) => {
        this.siteSettingsService.healthCheck(error);
      },
      complete: () => {
        this.siteSettingsService.healthCheck();
      }
    });
  }
}
