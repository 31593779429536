<div class="filter-dropdown mb-3 d-none d-sm-block">
  <button
    type="button"
    class="btn dropdown-toggle pr-0"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    data-popperConfig=""
    data-display="static"
    [disabled]="disabled"
  >
    <span>{{ label }}</span>
  </button>
  <ul class="dropdown-menu dropdown-list">
    <ng-container *ngFor="let option of options">
      <li
        class="dropdown-list__item dropdown-list__item--check-icon"
        [class]="option.selected ? 'selected' : null"
        *ngIf="dataService.dropdownOptionHasOffers(option)"
      >
        <img
          class="dropdown-list__item-checkbox-icon"
          src="../../../assets/images/check.svg"
        />
        <input
          [(ngModel)]="option.selected"
          type="checkbox"
          [checked]="option.selected"
          (change)="dataService.updateSelectedOptions()"
          id="{{ option.name }}"
        />
        <label class="py-2 break-word" for="{{ option.name }}">{{
          option.name
        }}</label>
      </li>
    </ng-container>
  </ul>
</div>

<!-- mobile -->
<div class="filter-dropdown mb-3 d-block d-sm-none">
  <button
    type="button"
    class="btn pr-0"
    aria-haspopup="true"
    aria-expanded="false"
    [disabled]="disabled"
    [routerLink]="['/filters', dropdownSlug]"
  >
    <span>{{ label }}</span>
    <img class="mr-2" src="../../../assets/images/expand-right-gray.svg" />
  </button>
</div>
