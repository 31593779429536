import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DataService,
  DropdownField,
  DropdownOption
} from 'src/app/services/data.service';

@Component({
  selector: 'app-filter-dropdown-options-mobile',
  templateUrl: './filter-dropdown-options-mobile.component.html',
  styleUrls: ['./filter-dropdown-options-mobile.component.scss']
})
export class FilterDropdownOptionsMobileComponent implements OnInit {
  dropdownOptions: DropdownOption[];
  dropdownField: DropdownField;
  @Output() selected: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    const filedName = this.route.snapshot.paramMap.get('dropdownSlug');

    try {
      if (this.dataService.dropdownFields.length === 0) {
        this.dataService.dropdownFields = await this.dataService.getDropdownFields();
      }
      this.dropdownField = await this.dataService.dropdownFields.find(
        (dropdownField) => {
          return dropdownField.field_name === filedName;
        }
      );
      this.dropdownOptions = this.dropdownField.dropdown_options;
    } catch (e) {
      console.log(e);

      switch (e.status) {
        case 404:
          this.router.navigate(['/404']);
          break;
        default:
          this.router.navigate(['/error']);
          break;
      }
    }
  }

  goBack(): void {
    window.history.back();
  }
}
