<app-page-header color="orange">
  <app-top-bar [compact]="true"></app-top-bar>
  <app-hero
    view="error"
    [title]="'COMMON.ERROR_DEFAULT' | translate"
  ></app-hero>
</app-page-header>

<app-page-content>
  <div class="container">
    <div class="bg-white p-3">
      <a class="btn btn-blue" [routerLink]="['/home']">{{
        'COMMON.ERROR_BACK_LABEL' | translate
      }}</a>
    </div>
  </div>
</app-page-content>

<app-footer></app-footer>
