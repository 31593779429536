import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  @Input() message: string;
  @Input() errors: [];
  @Input() class: string;
  @Input() model: string;

  constructor(private translationService: TranslateService) {}

  translateAttribute(attribute: string): string {
    return this.translationService.instant(
      `${this.model.toUpperCase()}.FIELDS.${attribute.toUpperCase()}`
    );
  }
}
