import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent {
  @Input() siteName = 'default';
  @Input() view: 'home' | 'detail' | 'error' = 'home';
  @Input() title = '';
  @Input() subtitle = '';
  @Input() subtitleSubheading = '';
  @Input() subtitleColor = 'orange';
}
