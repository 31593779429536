import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SITES } from 'src/app/config/sites';
import {
  SortingConfig,
  SortingService
} from 'src/app/services/sorting.service';

@Component({
  selector: 'app-offers-list-sort',
  templateUrl: './offers-list-sort.component.html',
  styleUrls: ['./offers-list-sort.component.scss']
})
export class OffersListSortComponent implements OnInit {
  @Input() siteName = 'default';
  @Output() sort: EventEmitter<SortingConfig> = new EventEmitter();

  ALLOWED_FIELDS: string[] = [];
  SORTABLE_FIELDS: string[] = [];
  COL_WIDTHS: string[] = [];

  sorting: { [key: string]: SortingConfig } = {};

  constructor(private sortingService: SortingService) {}

  ngOnInit(): void {
    const initialSorting = this.sortingService.getActiveSorting();
    this.initSiteConfig();

    this.SORTABLE_FIELDS.map((field) => {
      if (initialSorting && field === initialSorting.field) {
        this.sorting[field] = initialSorting;
      } else {
        this.sorting[field] = { field, order: null };
      }
    });
  }

  isSortable(field: string): boolean {
    return !!this.sorting[field];
  }

  onSort(field: string): void {
    // Alternate between ascending and descending sorting
    this.sorting[field].order =
      this.sorting[field].order === 'DESC' ? 'ASC' : 'DESC';

    // Reset all other sort fields
    Object.keys(this.sorting).forEach((key, index) => {
      if (key !== field) {
        this.sorting[key].order = null;
      }
    });

    this.sort.emit(this.sorting[field]);
  }

  private initSiteConfig(): void {
    this.ALLOWED_FIELDS =
      SITES[this.siteName].options.OFFERS_LIST.ALLOWED_COLUMNS;
    this.SORTABLE_FIELDS =
      SITES[this.siteName].options.OFFERS_LIST.SORTABLE_COLUMNS;
    this.COL_WIDTHS = SITES[this.siteName].options.OFFERS_LIST.COL_WIDTHS;
  }
}
