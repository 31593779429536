import { Component, OnInit } from '@angular/core';
import { SiteSettingService } from 'src/app/services/site-setting.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  constructor(
    private siteSettingService: SiteSettingService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  get text(): string {
    const siteSetting = this.siteSettingService.siteSetting;
    if (!siteSetting) {
      return this.translateService.instant('MAINTENANCE.GENERIC_TEXT');
    }
    return (
      this.siteSettingService.siteSetting.maintenance_text ||
      this.translateService.instant('MAINTENANCE.GENERIC_TEXT')
    );
  }
}
