import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @Input() offer;
  currentPage: number;
  offerIndex: number;

  constructor(
    public dataService: DataService,
    public utilService: UtilService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setOfferIndex(this.dataService.currentOfferIndex(this.offer));
  }

  setOfferIndex(idx: number): void {
    this.offerIndex = idx;
    this.currentPage = idx + 1;
  }

  keyDownFunction(event): void {
    if (event.keyCode === 13) {
      const idx = Number(event.target.value) - 1;
      if (this.canGo(idx)) {
        this.navigateToOtherOffer(Number(event.target.value) - 1);
      }
    }
  }

  focusOut(event): void {
    const idx = Number(event.target.value) - 1;
    if (this.canGo(idx)) {
      this.navigateToOtherOffer(Number(event.target.value) - 1);
    }
  }

  navigateToOtherOffer(otherIdx: number): void {
    this.setOfferIndex(otherIdx);
    this.dataService.offerChanged.next(
      this.dataService.filteredOffers[this.offerIndex]
    );
    this.router.navigate(
      this.utilService.offerRoute(
        this.dataService.filteredOffers[this.offerIndex]
      )
    );
  }

  canGoNext(): boolean {
    return this.dataService.filteredOffers.length > this.offerIndex + 1;
  }

  canGoPrevious(): boolean {
    return this.offerIndex - 1 >= 0;
  }

  canGo(index: number): boolean {
    return this.dataService.filteredOffers.length > index && index >= 0;
  }

  goToPreviousOffer(): void {
    const previousIndex = this.offerIndex - 1;
    if (this.canGoPrevious()) {
      this.navigateToOtherOffer(previousIndex);
    }
  }

  goToNextOffer(): void {
    const nextIndex = this.offerIndex + 1;
    if (this.canGoNext()) {
      this.navigateToOtherOffer(nextIndex);
    }
  }
}
