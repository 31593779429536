import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { SiteNames } from '../config/sites';
import { SiteSettingService } from '../services/site-setting.service';

@Directive({
  selector: '[appIfSite]'
})
export class IfSiteDirective {
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<Element>,
    private viewContainer: ViewContainerRef,
    private siteSettingService: SiteSettingService
  ) {}

  @Input('appIfSite') set appIfSite(name: SiteNames) {
    const currentSite = this.siteSettingService.siteSetting.site.name;

    if (name.toUpperCase() === currentSite.toUpperCase()) {
      if (!this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      }
    } else {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
