import { Component, Input, OnInit } from '@angular/core';
import { SiteSettingService } from 'src/app/services/site-setting.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() view: 'home' | 'other' = 'other';
  @Input() color = 'site-primary';
  siteName = 'default';
  currentYear: number;

  constructor(private siteSettingService: SiteSettingService) {}

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.siteName = this.siteSettingService.siteSetting.site.name;
  }
}
