import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-social-menu',
  templateUrl: './footer-social-menu.component.html',
  styleUrls: ['./footer-social-menu.component.scss']
})
export class FooterSocialMenuComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
