<ul [class]="type">
  <li *ngFor="let menuLink of links">
    <a
      class="text-{{ color }}"
      *ngIf="!menuLink.routerLink"
      [href]="menuLink.LINK"
      [attr.target]="newWindow || menuLink.newWindow ? '_blank' : null"
      >{{ menuLink.TITLE }}</a
    >
    <a
      class="text-{{ color }}"
      *ngIf="menuLink.routerLink"
      [attr.target]="newWindow || menuLink.newWindow ? '_blank' : null"
      [routerLink]="menuLink.routerLink"
      >{{ menuLink.TITLE }}</a
    >
  </li>
</ul>
