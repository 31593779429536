<div class="hero hero--{{ view }} container">
  <ng-container [ngSwitch]="view">
    <ng-container *ngSwitchCase="'home'">
      <div class="hero__bg-wrapper hero__bg-wrapper--{{ siteName }}">
        <div class="row d-sm-none">
          <div class="col-8"></div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'detail'">
      <div class="hero__bg-wrapper">
        <div class="row">
          <div class="col">
            <div class="hero__label color-{{ subtitleColor }}">
              {{ subtitle }}
            </div>

            <div
              class="hero__label hero__subheading"
              *ngIf="subtitleSubheading"
            >
              {{ subtitleSubheading }}
            </div>

            <h1 class="hero__title">{{ title }}</h1>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'error'">
      <div class="hero__bg-wrapper">
        <div class="row">
          <div class="col">
            <div class="hero__label color-{{ subtitleColor }}">
              {{ subtitle }}
            </div>
            <h1 class="hero__title">{{ title }}</h1>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
