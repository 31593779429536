<a [routerLink]="[url]">
  <div class="offer-item" [ngClass]="cssClass">
    <div class="row">
      <div
        *ngFor="let field of fields; index as i"
        class="break-word d-flex align-items-center"
        [ngClass]="[colWidths[i]]"
      >
        {{ offer && formatValue(offer[field]) }}
      </div>
      <div class="offer-item__arrow">
        <img src="../../../assets/icons/arrow-right.svg" />
      </div>
    </div>
  </div>
</a>
