<div class="alert alert-{{ class }}" role="alert" *ngIf="message">
  {{ message }}
</div>

<div class="alert alert-{{ class }}" role="alert" *ngIf="errors">
  <h5>{{ 'ALERT.ERRORS_APPEARED' | translate }}</h5>
  <ul>
    <li *ngFor="let error of errors | keyvalue">
      {{ translateAttribute(error.key) }}: {{ error.value }}
    </li>
  </ul>
</div>
