import { Component, Input, OnInit } from '@angular/core';
import { SiteSettingService } from 'src/app/services/site-setting.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  @Input() compact = false;

  siteName = 'default';

  constructor(private siteSettingService: SiteSettingService) {}

  ngOnInit(): void {
    this.siteName = this.siteSettingService.siteSetting.site.name;
  }
}
