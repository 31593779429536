import { Injectable } from '@angular/core';
import Offer from '../definitions/Offer.interface';

export interface SortingConfig {
  field: string;
  order: 'ASC' | 'DESC';
}

@Injectable({
  providedIn: 'root'
})
export class SortingService {
  activeSort: SortingConfig = null;

  constructor() {}

  sort(array: Array<Offer>, config: SortingConfig): Offer[] {
    // No sorting is specified, do not sort
    if (!config && !this.activeSort) {
      return;
    }
    // If config is passed, set it as active sort
    if (config) {
      this.activeSort = config;
    }
    // Use it to sort by or use the latest one stored in service
    const sortBy = config || this.activeSort;
    return array.sort((a, b) => this.compare(a, b, sortBy.field, sortBy.order));
  }

  getActiveSorting(): SortingConfig {
    if (this.activeSort) {
      return this.activeSort;
    }
  }

  private compare(
    a: Offer,
    b: Offer,
    field: string,
    order: 'DESC' | 'ASC'
  ): number {
    const propA = a[field];
    const propB = b[field];
    const asc = order === 'ASC';

    if (propA > propB) {
      return asc ? 1 : -1;
    }
    if (propA < propB) {
      return asc ? -1 : 1;
    }
    return 0;
  }
}
