<ng-container>
  <div class="container bg-white" *ngIf="offer">
    <div class="form-wrapper">
      <form class="form" [formGroup]="bookingFormGroup">
        <app-alert
          [errors]="backendErrors.errors"
          id="alert"
          class="danger mb-4"
          *ngIf="backendErrors"
          model="BOOKINGS"
        >
        </app-alert>
        <div class="form-group mb-4">
          <div class="row">
            <div class="col-12">
              <p class="interested-in">
                {{ 'OFFER_BOOKING.INTERESTED_IN' | translate }}
              </p>
              <h5>
                {{ offer.title }}
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <app-filter-selection
                readOnly="true"
                [selected]="dataService.selectedOptions"
              ></app-filter-selection>
            </div>
          </div>
        </div>

        <!-- CHECKBOXES -->
        <div class="form-group">
          <div class="row">
            <div class="col-12">
              <div class="form-check custom-form-check">
                <input
                  formControlName="callback"
                  type="checkbox"
                  class="form-check-input"
                  id="checkbox-callback"
                  name="checkbox-callback"
                />
                <label class="form-check-label" for="checkbox-callback">{{
                  'BOOKINGS.FIELDS.CALLBACK' | translate
                }}</label>
              </div>

              <div class="form-check custom-form-check">
                <input
                  formControlName="more_info"
                  type="checkbox"
                  class="form-check-input"
                  id="checkbox-more-info"
                  name="checkbox-more-info"
                />
                <label class="form-check-label" for="checkbox-more-info">{{
                  'BOOKINGS.FIELDS.MORE_INFO' | translate
                }}</label>
              </div>

              <div class="form-check custom-form-check">
                <input
                  formControlName="register_other_person"
                  type="checkbox"
                  class="form-check-input"
                  id="checkbox-register-other-person"
                  name="checkbox-register-other-person"
                />
                <label
                  class="form-check-label"
                  for="checkbox-register-other-person"
                  >{{
                    'BOOKINGS.FIELDS.REGISTER_OTHER_PERSON' | translate
                  }}</label
                >
              </div>
            </div>
          </div>
        </div>

        <!-- MESSAGE -->
        <div class="form-group">
          <label>{{ 'OFFER_BOOKING.REQUEST' | translate }}</label>
          <div class="row">
            <div class="col-12">
              <textarea
                class="form-control {{ hasError('message') && 'is-invalid' }}"
                name="message"
                formControlName="message"
                placeholder="{{ 'BOOKINGS.FIELDS.MESSAGE' | translate }}"
                rows="5"
              ></textarea>
            </div>
          </div>
        </div>

        <!-- CONTACT -->
        <div class="form-group">
          <label>{{ 'OFFER_BOOKING.CONTACT' | translate }}</label>
          <div class="row">
            <div class="col-12 col-md-6">
              <input
                class="form-control {{
                  hasError('first_name') && 'is-invalid'
                }}"
                name="title"
                type="text"
                formControlName="first_name"
                placeholder="{{ 'BOOKINGS.FIELDS.FIRST_NAME' | translate }}"
                required
              />
              <label
                class="text-danger"
                *ngIf="showErrors && hasError('first_name')"
              >
                {{ errorFor('first_name') }}
              </label>
            </div>
            <div class="col-12 col-md-6">
              <input
                class="form-control {{ hasError('last_name') && 'is-invalid' }}"
                name="title"
                type="text"
                formControlName="last_name"
                placeholder="{{ 'BOOKINGS.FIELDS.LAST_NAME' | translate }}"
                required
              />
              <label
                class="text-danger"
                *ngIf="showErrors && hasError('last_name')"
              >
                {{ errorFor('last_name') }}
              </label>
            </div>
          </div>

          <div class="row mt-lg-3 mt-xl-3 mt-md-3">
            <div class="col-12">
              <input
                class="form-control {{ hasError('community') && 'is-invalid' }}"
                name="title"
                type="text"
                formControlName="community"
                placeholder="{{ 'BOOKINGS.FIELDS.COMMUNITY' | translate }}"
                required
              />
              <label
                class="text-danger"
                *ngIf="showErrors && hasError('community')"
              >
                {{ errorFor('community') }}
              </label>
            </div>
          </div>

          <div class="row mt-lg-3 mt-xl-3 mt-md-3">
            <div class="col-12">
              <input
                class="form-control {{ hasError('phone') && 'is-invalid' }}"
                name="title"
                type="text"
                formControlName="phone"
                placeholder="{{ 'BOOKINGS.FIELDS.PHONE' | translate }}"
                required
              />
              <label
                class="text-danger"
                *ngIf="showErrors && hasError('phone')"
              >
                {{ errorFor('phone') }}
              </label>
            </div>
          </div>

          <div class="row mt-lg-3 mt-xl-3 mt-md-3">
            <div class="col-12">
              <input
                class="form-control {{ hasError('email') && 'is-invalid' }}"
                name="title"
                type="text"
                formControlName="email"
                placeholder="{{ 'BOOKINGS.FIELDS.EMAIL' | translate }}"
                required
              />
              <label
                class="text-danger"
                *ngIf="showErrors && hasError('email')"
              >
                {{ errorFor('email') }}
              </label>
            </div>
          </div>
        </div>

        <!-- CLIENT -->
        <div class="form-group">
          <label>{{ 'OFFER_BOOKING.CLIENT' | translate }}</label>
          <div class="row">
            <div class="col-12">
              <input
                class="form-control {{
                  hasError('client_abbreviation') && 'is-invalid'
                }}"
                name="client_abbreviation"
                type="text"
                formControlName="client_abbreviation"
                placeholder="{{
                  'BOOKINGS.FIELDS.CLIENT_ABBREVIATION' | translate
                }}"
                required
              />
              <label
                class="text-danger"
                *ngIf="showErrors && hasError('client_abbreviation')"
              >
                {{ errorFor('client_abbreviation') }}
              </label>
            </div>
          </div>
        </div>

        <div class="d-flex flex-column">
          <div class="recaptcha">
            <re-captcha
              required
              formControlName="recaptchaReactive"
            ></re-captcha>
          </div>
          <label
            class="text-danger"
            *ngIf="showErrors && hasError('recaptchaReactive')"
          >
            {{ errorFor('recaptchaReactive') }}
          </label>
          <button class="btn btn-primary btn-wide btn-right" (click)="submit()">
            {{ 'OFFER_BOOKING.BOOK' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-container>
