import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-more-filters-mobile',
  templateUrl: './more-filters-mobile.component.html',
  styleUrls: ['./more-filters-mobile.component.scss']
})
export class MoreFiltersMobileComponent implements OnInit {
  constructor(public dataService: DataService) {}

  ngOnInit(): void {}

  goBack(): void {
    window.history.back();
  }
}
