<div class="offer-detail-body">
  <ul class="list-group">
    <ng-container *ngFor="let item of offerFields">
      <ng-container
        *ngIf="
          item.id !== 'price_detail' &&
          item.id !== 'cancellation_policy' &&
          !offerType[item.id]
        "
      >
        <li class="list-group-item">
          <div class="row">
            <div class="col-sm-4 px-0">
              <span class="font-weight-bold break-word">{{ item.label }}</span>
            </div>
            <div class="col-sm-8 px-0">
              <div *ngIf="item.id !== 'registrations'">
                <span
                  *ngIf="!isEmail(item.value) && !isUrl(item.value)"
                  class="text-darkgray text-wrap-pre-wrap break-word markdown"
                  markdown
                  >{{ item.value }}</span
                >
                <a
                  [href]="[
                    'mailto:' +
                      email +
                      '?subject=' +
                      offer.internal_reference +
                      ' - ' +
                      offer.title
                  ]"
                  *ngIf="isEmail(item.value)"
                  class="text-lightblue break-word"
                >
                  {{ item.value }}
                </a>
                <a
                  [href]="[url]"
                  *ngIf="isUrl(item.value)"
                  class="text-lightblue ellipsis"
                  target="_blank"
                >
                  {{ item.value }}
                </a>
                <a
                  [href]="[offer.external_url_2]"
                  *ngIf="item.id === 'external_url' && offer.external_url_2"
                  class="text-lightblue ellipsis"
                  target="_blank"
                >
                  {{ offer.external_url_2 }}
                </a>
                <a
                  [href]="[offer.external_offer_url_2]"
                  *ngIf="
                    item.id === 'external_offer_url' &&
                    offer.external_offer_url_2
                  "
                  class="text-lightblue ellipsis"
                  target="_blank"
                >
                  {{ offer.external_offer_url_2 }}
                </a>
              </div>
              <div
                class="list-group list-group--registrations list-group__link bg-transparent"
                *ngIf="item.id === 'registrations'"
              >
                <app-links-list
                  [links]="item.value"
                  type="form-inline"
                ></app-links-list>
              </div>
            </div>
          </div>
        </li>
      </ng-container>
      <ng-container *ngIf="item.id === 'price_detail'">
        <app-offer-detail-accordion
          [label]="item.label"
          [data]="offer.offer_pricings"
          [fields]="OFFER_DETAIL_ACCORDION_FIELDS"
          [fieldsOther]="OFFER_DETAIL_ACCORDION_FIELDS_OTHER"
          [columnWidths]="OFFER_DETAIL_ACCORDION_COL_WIDTHS"
          [noTableSplitting]="offer.no_price_table_splitting"
          [noBaseOffer]="offer.no_base_offer"
          model="OFFER_PRICINGS"
          type="table"
        ></app-offer-detail-accordion>
      </ng-container>

      <ng-container *ngIf="item.id === 'cancellation_policy'">
        <app-offer-detail-accordion
          [label]="item.label"
          [data]="offer.cancellation_policy"
          type="text"
        ></app-offer-detail-accordion>
      </ng-container>
    </ng-container>
  </ul>

  <div class="mt-4" *ngIf="offer && !isPreview">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <button
          class="btn btn-primary btn-wide btn-width-50-lg offer-detail-body__btn-home"
          [routerLink]="['/home']"
        >
          {{ 'OFFER_DETAIL.BACK_TO_HOME' | translate }}
        </button>
      </div>
      <div class="col-12 col-md-6">
        <app-navigation [offer]="offer"> </app-navigation>
      </div>
    </div>
  </div>
</div>
