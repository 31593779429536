import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { UtilService } from 'src/app/services/util.service';
import { DataService, Department } from '../../services/data.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  departments$: Observable<Department[]> | undefined;
  searchTermControl = new FormControl();

  constructor(
    public dataService: DataService,
    public utilsService: UtilService
  ) {}

  categoryChecked(category: Department): boolean {
    for (const subcategory of category.offer_types) {
      if (!this.dataService.categorySelected(subcategory.name)) {
        return false;
      }
    }
    return true;
  }

  toggleSubCategories(category: Department): void {
    const nextCategoryCheckboxValue = !this.categoryChecked(category);
    for (const subcategory of category.offer_types) {
      this.dataService.forceCategory(subcategory, nextCategoryCheckboxValue);
    }
  }

  ngOnInit(): void {
    this.searchTermControl.setValue(this.dataService.searchTerm);
    this.searchTermControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value) => {
        this.dataService.searchTerm = value;
        this.dataService.filterResults();
      });

    this.departments$ = this.dataService
      .getDepartments()
      .pipe(map((offerTypes) => offerTypes.slice(0, 4)));
  }
}
