<div class="offers-list">
  <!-- only show on mobile -->
  <div class="container-fluid d-block d-sm-none px-0">
    <ng-container *ngIf="isLoading; else offersListTemplateMobile">
      <div class="offers-list__spinner list-group-item text-center">
        <div class="spinner-border text-dark" role="status">
          <span class="sr-only">{{ 'COMMON.LOADING' | translate }}</span>
        </div>
      </div>
    </ng-container>
    <ng-template #offersListTemplateMobile>
      <ng-container *ngFor="let offer of dataService.filteredOffers">
        <app-offer-item-mobile
          [siteName]="siteName"
          [offer]="offer"
        ></app-offer-item-mobile>
      </ng-container>
    </ng-template>
  </div>

  <div class="container-fluid d-none d-sm-block mt-4">
    <div class="row overflow-auto">
      <div class="offers-list__wrapper">
        <div class="row">
          <div class="col">
            <app-offers-list-sort
              [siteName]="siteName"
              (sort)="sort($event)"
            ></app-offers-list-sort>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <ng-container *ngIf="isLoading; else offersListTemplate">
              <div class="offers-list__spinner list-group-item text-center">
                <div class="spinner-border text-dark" role="status">
                  <span class="sr-only">{{
                    'COMMON.LOADING' | translate
                  }}</span>
                </div>
              </div>
            </ng-container>
            <ng-template #offersListTemplate>
              <ng-container *ngFor="let offer of dataService.filteredOffers">
                <app-offer-item
                  [siteName]="siteName"
                  [offer]="offer"
                ></app-offer-item>
              </ng-container>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
