<div
  *ngIf="offer"
  class="offer-item bg-white mb-3 p-3"
  [ngClass]="cssClass"
  (click)="navigateToDetail()"
>
  <div class="d-flex justify-content-between align-items-center">
    <div>
      <ng-container *ngFor="let field of fields">
        <div class="{{ field }}">
          {{ offer[field] }}
        </div>
      </ng-container>
    </div>
    <div class="arrow">
      <img src="../../../assets/images/expand-right-gray.svg" />
    </div>
  </div>
</div>
