import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SITES } from 'src/app/config/sites';
import Offer from 'src/app/definitions/Offer.interface';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-offer-item-mobile',
  templateUrl: './offer-item-mobile.component.html',
  styleUrls: ['./offer-item-mobile.component.scss']
})
export class OfferItemMobileComponent implements OnInit {
  @Input() siteName = 'default';
  @Input() offer: Offer;
  @Input() cssClass = '';

  fields: string[] = [];

  constructor(private router: Router, private utilService: UtilService) {}

  ngOnInit(): void {
    this.fields =
      SITES[this.siteName].options.OFFERS_LIST.ALLOWED_COLUMNS_MOBILE;
  }

  navigateToDetail(): void {
    const slug = this.utilService.getOfferDetailSlug(this.offer);

    this.router.navigate(['/detail', slug]);
  }
}
