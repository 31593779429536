import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SITES } from 'src/app/config/sites';
import Offer from 'src/app/definitions/Offer.interface';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-offer-item',
  templateUrl: './offer-item.component.html',
  styleUrls: ['./offer-item.component.scss']
})
export class OfferItemComponent implements OnInit {
  @Input() siteName = 'default';
  @Input() offer: Offer;
  @Input() cssClass = '';
  url: string;

  fields: string[] = [];
  colWidths: string[] = [];

  constructor(private router: Router, private utilService: UtilService) {}

  ngOnInit(): void {
    this.fields = SITES[this.siteName].options.OFFERS_LIST.ALLOWED_COLUMNS;
    this.colWidths = SITES[this.siteName].options.OFFERS_LIST.COL_WIDTHS;

    if (this.offer) {
      const slug = this.utilService.getOfferDetailSlug(this.offer);
      this.url = `/detail/${slug}`;
    }
  }

  formatValue(value: unknown[] | string): string {
    if (Array.isArray(value)) {
      return value.join(', ');
    } else {
      return value;
    }
  }
}
