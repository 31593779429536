<app-page-header>
  <div class="back-btn d-flex align-items-center" (click)="goBack()">
    <img class="mr-1" src="../../../assets/images/back.svg" />
    {{ 'BACK_BUTTON' | translate }}
  </div>
</app-page-header>

<app-page-content class="dropdown-options" *ngIf="dropdownField">
  <div class="dropdown-options__title">
    {{ dropdownField.name }}
  </div>
  <ul class="list-group dropdown-options__list">
    <ng-container *ngFor="let option of dropdownOptions">
      <li class="list-group-item bg-transparent dropdown-options__item px-0">
        <input
          [(ngModel)]="option.selected"
          type="checkbox"
          [checked]="option.selected"
          (change)="dataService.updateSelectedOptions()"
          id="{{ option.name }}"
        />
        <label class="mb-0" for="{{ option.name }}">{{ option.name }}</label>
      </li>
    </ng-container>
  </ul>
</app-page-content>

<footer>
  <div
    class="bg-white fixed-bottom d-flex justify-content-center align-items-center"
  >
    <button class="btn btn-blue my-3 py-2 px-5" routerLink="/">
      Speichern
    </button>
  </div>
</footer>
