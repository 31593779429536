import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from './../../services/data.service';

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss']
})
export class FilterDropdownComponent implements OnInit {
  @Input() label: string;
  @Input() dropdownSlug: string;
  @Input() options: any;
  @Input() disabled: boolean;
  @Output() selected: EventEmitter<boolean> = new EventEmitter();

  constructor(public dataService: DataService) {}

  ngOnInit(): void {}
}
