import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import Offer from 'src/app/definitions/Offer.interface';
import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'app-offer-booking',
  templateUrl: './offer-booking.component.html',
  styleUrls: ['./offer-booking.component.scss']
})
export class OfferBookingComponent implements OnInit {
  offer: Offer;
  isLoading = false;

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private matomoTracker: MatomoTracker,
    private utilService: UtilService
  ) {
    //
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    const slug = this.route.snapshot.paramMap.get('offerSlug');
    const id = this.utilService.getIdFromSlug(slug);

    this.matomoTracker.trackPageView(`booking: ${slug}`);
    try {
      this.offer = await this.dataService.getOffer(id);
      this.isLoading = false;
    } catch (e) {
      console.log(e);
      this.isLoading = false;
      switch (e.status) {
        case 404:
          this.router.navigate(['/404']);
          break;
        default:
          this.router.navigate(['/error']);
          break;
      }
    }
  }
}
