import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './components/app-layout/app-layout.component';
import { StlyeguideComponent } from './components/stlyeguide/stlyeguide.component';
import { ErrorComponent } from './pages/error/error.component';
import { FilterDropdownOptionsMobileComponent } from './pages/filter-dropdown-options-mobile/filter-dropdown-options-mobile.component';
import { HomeComponent } from './pages/home/home.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { MoreFiltersMobileComponent } from './pages/more-filters-mobile/more-filters-mobile.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { OfferBookingComponent } from './pages/offer-booking/offer-booking.component';
import { OfferDetailComponent } from './pages/offer-detail/offer-detail.component';
import { OfferEditComponent } from './pages/offer-edit/offer-edit.component';
import { OfferPreviewComponent } from './pages/offer-preview/offer-preview.component';
import { GlobalSiteSettingsResolver } from './resolvers/global-site-settings.resolver';

const routes: Routes = [
  { path: 'styleguide', component: StlyeguideComponent },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AppLayoutComponent,
    resolve: { siteSettings: GlobalSiteSettingsResolver },
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      { path: 'filters', component: MoreFiltersMobileComponent },
      {
        path: 'filters/:dropdownSlug',
        component: FilterDropdownOptionsMobileComponent
      },
      {
        path: 'detail/:offerSlug',
        component: OfferDetailComponent
      },
      {
        path: 'offer/:uid/edit',
        component: OfferEditComponent
      },
      {
        path: 'maintenance',
        component: MaintenanceComponent
      },
      {
        path: 'booking/:offerSlug',
        component: OfferBookingComponent
      },
      { path: 'offer/:uid/edit', component: OfferEditComponent },
      { path: 'offer/:uid/preview', component: OfferPreviewComponent },
      { path: 'maintenance', component: MaintenanceComponent },
      { path: '404', component: NotFoundComponent },
      { path: 'error', component: ErrorComponent }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
