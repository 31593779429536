import { Component, OnInit } from '@angular/core';
import { SiteOptions } from 'src/app/config/default-options';
import { SITES } from 'src/app/config/sites';
import { DataService } from 'src/app/services/data.service';
import { SiteSettingService } from 'src/app/services/site-setting.service';
import {
  SortingConfig,
  SortingService
} from 'src/app/services/sorting.service';

@Component({
  selector: 'app-offers-list',
  templateUrl: './offers-list.component.html',
  styleUrls: ['./offers-list.component.scss']
})
export class OffersListComponent implements OnInit {
  siteName: string;
  isLoading = false;
  SORTABILITY_MAPPING: SiteOptions['OFFERS_LIST']['SORTABILITY_MAPPING'];

  constructor(
    public dataService: DataService,
    private siteSettingService: SiteSettingService,
    private sortingService: SortingService
  ) {}

  async ngOnInit(): Promise<void> {
    this.siteName = this.siteSettingService.siteSetting.site.name;
    this.SORTABILITY_MAPPING =
      SITES[this.siteName].options.OFFERS_LIST.SORTABILITY_MAPPING;

    try {
      this.isLoading = true;
      if (this.dataService.offers.length === 0) {
        await this.dataService.updateOffers();
      }
      this.isLoading = false;
    } catch (e) {
      console.log(e);
      this.isLoading = false;
    }
  }

  sort(sortBy: SortingConfig = null): void {
    // if the field maps to other field (eg. field is serving as an helper)
    // example formatted_price => price as we want numeric comparsion
    if (this.SORTABILITY_MAPPING[sortBy.field]) {
      sortBy.field = this.SORTABILITY_MAPPING[sortBy.field];
    }

    this.sortingService.sort(this.dataService.filteredOffers, sortBy);
  }
}
