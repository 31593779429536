<div
  (click)="toggleShow()"
  class="btn p-0 tooltip__icon d-flex align-items-center justify-content-center"
>
  <img src="../../../assets/images/get-info.svg" alt="" />
  <div class="tooltip" *ngIf="utilService.activeTooltip === this">
    <div class="d-flex justify-content-end">
      <img class="tooltip__close" src="../../../assets/icons/close.svg" />
    </div>
    <p class="tooltip__text break-word my-2">{{ text }}</p>
  </div>
</div>
