<ng-container *ngIf="departments$ | async as departments">
  <!-- Desktop -->
  <div class="app-filters-desktop d-none d-sm-block">
    <section class="container">
      <div class="filters">
        <ng-container *appFeatureEnabled="'enableFullTextSearch'">
          <h2>{{ 'HOME.FILTER-TITLE' | translate }}</h2>
          <div class="row">
            <div class="col-12 mb-3">
              <input
                type="text"
                [formControl]="searchTermControl"
                class="form-control search-box search-box--desktop"
                placeholder="{{ 'HOME.SEARCH_PLACEHOLDER' | translate }}"
              />
            </div>
          </div>
        </ng-container>

        <ng-container *appFeatureEnabled="'enableDepartmentsFilter'">
          <div class="row">
            <ng-container
              *ngFor="let cat of departments; index as categoryIndex"
            >
              <div class="col-xl-3 col-lg-4 col-md-6 mb-3">
                <ul class="list-group">
                  <!-- TODO colorize based on API -->
                  <li
                    class="list-group-item filters__item--main {{
                      utilsService.categoryColorForDepartment(cat.name)
                    }}"
                  >
                    <div class="custom-control custom-checkbox">
                      <input
                        [checked]="categoryChecked(cat)"
                        (change)="toggleSubCategories(cat)"
                        type="checkbox"
                        class="filters__toggle custom-control-input"
                        id="cat-{{ categoryIndex }}"
                      />
                      <label
                        class="custom-control-label main-category {{
                          utilsService.categoryColorForDepartment(cat.name)
                        }}"
                        for="cat-{{ categoryIndex }}"
                        >{{ cat.name }}</label
                      >
                    </div>
                  </li>
                  <ng-container
                    *ngFor="let subcat of cat.offer_types; index as offerIndex"
                  >
                    <li
                      class="list-group-item filters__item--sub d-flex align-items-center justify-content-between"
                    >
                      <div class="custom-control custom-checkbox">
                        <input
                          #customControl
                          [checked]="dataService.categorySelected(subcat.name)"
                          (change)="
                            dataService.toggleCategory(subcat);
                            customControl.blur()
                          "
                          type="checkbox"
                          class="filters__toggle custom-control-input"
                          id="subcat-{{ categoryIndex }}{{ offerIndex }}"
                        />
                        <label
                          class="custom-control-label {{
                            utilsService.categoryColorForDepartment(cat.name)
                          }}"
                          for="subcat-{{ categoryIndex }}{{ offerIndex }}"
                          >{{ subcat.name }}</label
                        >
                      </div>
                      <app-tooltip
                        *ngIf="subcat.tooltip_info"
                        [text]="subcat.tooltip_info"
                      ></app-tooltip>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <div class="row mt-2">
          <ng-container *ngFor="let dropdown of dataService.dropdownFields">
            <div
              class="col-lg-3 col-md-4 col-sm-6"
              *ngIf="dataService.dropdownOptionAvailable(dropdown)"
            >
              <app-filter-dropdown
                [label]="dropdown.name_short || dropdown.name"
                [options]="dropdown.dropdown_options"
                (selected)="filterDropdownSelected(event)"
              ></app-filter-dropdown>
            </div>
          </ng-container>
          <div class="existing-choise d-none d-sm-block">
            {{ 'HOME.OFFERS_COUNT_LABEL' | translate }}:&nbsp;<b>{{
              dataService.filteredOffers.length
            }}</b>
          </div>
        </div>
      </div>
    </section>

    <section class="container bg-transparent px-0 py-0">
      <app-filter-selection
        [selected]="dataService.selectedOptions"
      ></app-filter-selection>
    </section>
  </div>

  <!-- Mobile -->
  <div class="app-filters-mobile d-block d-sm-none">
    <section class="container">
      <div class="filters">
        <ng-container *appFeatureEnabled="'enableFullTextSearch'">
          <h2>{{ 'HOME.FILTER-TITLE' | translate }}</h2>
          <div class="row">
            <input
              type="text"
              [formControl]="searchTermControl"
              class="form-control search-box search-box--mobile"
              placeholder="{{ 'HOME.SEARCH_PLACEHOLDER' | translate }}"
            />
          </div>
        </ng-container>

        <ng-container *appFeatureEnabled="'enableDepartmentsFilter'">
          <div class="row">
            <ng-container
              *ngFor="let cat of departments; index as categoryIndex"
            >
              <!-- only work on mobile -->
              <div class="filter-dropdown mb-3 col-12 px-0">
                <button
                  type="button"
                  class="btn dropdown-toggle filters__item--main {{
                    utilsService.categoryColorForDepartment(cat.name)
                  }}"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  [disabled]="disabled"
                >
                  <span>{{ cat.name }}</span>
                </button>
                <ul class="dropdown-menu dropdown-list filters__item--menu">
                  <ng-container
                    *ngFor="let subcat of cat.offer_types; index as offerIndex"
                  >
                    <li class="list-group-item filters__item--sub">
                      <div class="custom-control custom-checkbox">
                        <input
                          [checked]="dataService.categorySelected(subcat.name)"
                          (change)="dataService.toggleCategory(subcat)"
                          type="checkbox"
                          class="filters__toggle custom-control-input"
                          id="subcat-{{ categoryIndex }}{{ offerIndex }}"
                        />
                        <label
                          class="custom-control-label {{
                            utilsService.categoryColorForDepartment(cat.name)
                          }}"
                          for="subcat-{{ categoryIndex }}{{ offerIndex }}"
                          >{{ subcat.name }}</label
                        >
                      </div>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <div
          routerLink="/filters"
          class="row d-flex justify-content-between filters__loadmore"
        >
          <div>{{ 'HOME.MORE-FILTERS' | translate }}</div>
          <img src="../../../assets/images/expand-right-gray.svg" />
        </div>
      </div>

      <div class="row my-2">
        <div class="col-auto">
          {{ 'HOME.OFFERS_COUNT_LABEL' | translate }}:&nbsp;<b>{{
            dataService.filteredOffers.length
          }}</b>
        </div>
      </div>
    </section>
  </div>
</ng-container>
