import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MatomoTracker } from 'ngx-matomo';
import { SiteSettingService } from 'src/app/services/site-setting.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  siteName = 'default';

  constructor(
    public meta: Meta,
    public title: Title,
    private matomoTracker: MatomoTracker,
    private translateService: TranslateService,
    private siteSettingService: SiteSettingService
  ) {}

  ngOnInit(): void {
    this.siteName = this.siteSettingService.siteSetting.site.name;

    this.translateService.get('HOME.META_DESCRIPTION').subscribe((text) => {
      this.meta.addTag({ name: 'description', content: text });
    });
    this.translateService
      .get(`HOME.${this.siteName.toUpperCase()}.META_TITLE`)
      .subscribe((text) => {
        this.title.setTitle(text);
      });
    this.matomoTracker.trackPageView('Home visit');
  }
}
