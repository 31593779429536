<div class="offers-list-sort">
  <div class="row">
    <ng-container *ngFor="let field of ALLOWED_FIELDS; index as i">
      <div class="{{ COL_WIDTHS[i] }} pr-2 pb-3">
        <div
          (click)="onSort(field)"
          class="offers-list-sort__wrapper"
          *ngIf="isSortable(field); else unsortableField"
        >
          <span class="mr-2">
            {{ 'OFFERS.FIELDS.' + field.toUpperCase() | translate }}
          </span>
          <div class="offers-list-sort__controls">
            <ng-container *ngIf="sorting[field].order; else noSortActive">
              <img
                src="../../../assets/icons/sort_{{
                  sorting[field].order.toLowerCase()
                }}.svg"
              />
            </ng-container>
            <ng-template #noSortActive>
              <img src="../../../assets/icons/sort.svg" />
            </ng-template>
          </div>
        </div>

        <ng-template #unsortableField>
          <div class="offers-list-sort__wrapper">
            <span class="mr-2">
              {{ 'OFFERS.FIELDS.' + field.toUpperCase() | translate }}
            </span>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>
</div>
