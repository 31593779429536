import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SITE_NAMES, SiteNames } from '../config/sites';
import { DataService } from './data.service';

export interface Site {
  name: SiteNames;
}

export interface SiteSetting {
  maintenance: boolean;
  maintenance_text: string;
  site: Site;
  dropdown_fields: string[];
}

@Injectable({
  providedIn: 'root'
})
export class SiteSettingService {
  private API_URL = environment.apiUrl;
  private _siteSetting: SiteSetting = {
    maintenance: false,
    maintenance_text: '',
    site: {
      name: 'default'
    },
    dropdown_fields: []
  };
  healthCheckDone: boolean;

  get siteSetting(): SiteSetting {
    return this._siteSetting;
  }

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private router: Router,
    private dataService: DataService
  ) {}

  save(value: SiteSetting): void {
    // Check if site name is configured in `config/sites.ts`.
    if (SITE_NAMES.includes(value.site?.name)) {
      this._siteSetting = value;
      this.dataService.standardFilters = value.dropdown_fields;
    } else {
      console.error(
        `Unknown site name: ${value.site?.name}. This probably means that the site is not configured in the client. Fallback to default`
      );
    }
  }

  healthCheck(error?: unknown): void {
    if (error) {
      this._siteSetting = {
        ...this.siteSetting,
        maintenance_text: this.translateService.instant(
          'MAINTENANCE.GENERIC_TEXT'
        ),
        maintenance: true
      };
      this.router.navigate(['/maintenance']);
      this.healthCheckDone = true;
      return;
    }

    if (this.siteSetting.maintenance) {
      this.router.navigate(['/maintenance']);
      return;
    }

    if (
      !this.siteSetting.maintenance &&
      this.router.url.includes('maintenance')
    ) {
      this.router.navigate(['/']);
      return;
    }
  }

  getSiteSetting(): Observable<SiteSetting> {
    return this.http.get<SiteSetting>(`${this.API_URL}/site_settings`);
  }
}
