import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import {
  SiteSetting,
  SiteSettingService
} from '../services/site-setting.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalSiteSettingsResolver implements Resolve<SiteSetting> {
  constructor(private siteSettingsService: SiteSettingService) {}

  resolve(): Observable<SiteSetting> {
    return this.siteSettingsService.getSiteSetting();
  }
}
