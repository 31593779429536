import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';

export enum AccordionType {
  TABLE = 'table',
  TEXT = 'text'
}

@Component({
  selector: 'app-offer-detail-accordion',
  templateUrl: './offer-detail-accordion.component.html',
  styleUrls: ['./offer-detail-accordion.component.scss']
})
export class OfferDetailAccordionComponent implements OnInit {
  @Input() expanded = false;
  @Input() label: string;
  @Input() data: [] | string;
  @Input() fields: string[];
  @Input() fieldsOther: [];
  @Input() columnWidths: [];
  @Input() model: string;
  @Input() type: string;
  @Input() noTableSplitting = true;
  @Input() noBaseOffer = true;

  hasData: boolean;

  constructor(private utilService: UtilService) {}

  ngOnInit(): void {
    this.hasData = !!(this.data && this.data.length);
  }

  isTable(): boolean {
    return this.type === AccordionType.TABLE;
  }

  headers(): string[] {
    return this.fields.map((field) =>
      this.utilService.translateAttribute(this.model, field)
    );
  }

  headersOther(): string[] {
    return this.fieldsOther.map((field) =>
      this.utilService.translateAttribute(this.model, field)
    );
  }

  toggleExpand(): void {
    this.expanded = !this.expanded;
  }
}
