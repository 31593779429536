<app-page-header
  [color]="utilService.categoryColorForDepartment(offer?.department_name)"
>
  <app-top-bar [compact]="true"></app-top-bar>
  <!-- TODO: subtitle is placeholder, replace with correct attribute of offer -->
  <app-hero
    view="detail"
    [title]="offer?.title"
    [subtitle]="offer?.offer_type_name || 'Lädt...'"
    [subtitleSubheading]="offer?.institution"
    [subtitleColor]="
      utilService.categoryColorForDepartment(offer?.department_name)
    "
  ></app-hero>
</app-page-header>

<app-page-content>
  <ng-container *ngIf="isLoading; else detailTemplate">
    <div class="offers-list__spinner list-group-item text-center">
      <div class="spinner-border text-dark" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-container>
  <ng-template #detailTemplate>
    <ng-container *appIfSite="'mba'">
      <div class="container mb-3 mb-md-4">
        <app-info-box text="{{ 'OFFER_DETAIL.MBA.INFO_BOX_TEXT' | translate }}">
        </app-info-box>
      </div>
    </ng-container>
    <div class="container" *ngIf="offer">
      <app-offer-detail-body
        [siteName]="siteName"
        [offer]="offer"
        [offerType]="offerType"
        [isPreview]="isPreview"
      ></app-offer-detail-body>
    </div>
  </ng-template>
</app-page-content>

<app-footer
  [color]="utilService.categoryColorForDepartment(offer?.department_name)"
></app-footer>
