<li class="list-group-item" *ngIf="hasData">
  <div class="row" (click)="toggleExpand()">
    <div class="col-8 px-0 offer-detail-accordion__clickable">
      <span class="font-weight-bold">{{ label }}</span>
    </div>

    <div class="col-4 px-0 offer-detail-accordion__clickable">
      <div
        class="list-group list-group--registrations list-group__link bg-transparent"
      >
        <div
          class="offer-detail-accordion__button w-100 d-flex justify-content-end align-items-center"
        >
          <img
            src="../../../assets/icons/{{ expanded ? 'minus' : 'plus' }}.svg"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="offer-detail-accordion__body mt-4" *ngIf="expanded">
    <ng-container *ngIf="isTable(); else isText">
      <div class="row mb-2">
        <div
          class="offer-detail-accordion__header mb-2"
          [ngClass]="[
            columnWidths[i],
            fields[i] === 'price' ? 'offer-detail-accordion__header-price' : ''
          ]"
          *ngFor="let header of headers(); index as i"
        >
          {{ header }}
        </div>
      </div>
      <div class="row">
        <ng-container *ngFor="let item of data; let i = index">
          <ng-container *ngIf="i == 0 || noTableSplitting">
            <div
              class="offer-detail-accordion__data mb-2 break-word"
              [ngClass]="[
                columnWidths[i],
                fields[i] === 'price'
                  ? 'offer-detail-accordion__data-last-column'
                  : ''
              ]"
              *ngFor="let field of fields; index as i"
            >
              <span>
                {{ item[field] }}
              </span>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <ng-container
        *ngIf="data && data.length && data.length > 1 && !noTableSplitting"
      >
        <div class="row mb-2">
          <div
            class="offer-detail-accordion__header mb-2"
            [ngClass]="[
              columnWidths[i],
              fields[i] === 'price'
                ? 'offer-detail-accordion__header-price'
                : ''
            ]"
            *ngFor="let header of headersOther(); index as i"
          >
            {{ header }}
          </div>
        </div>
      </ng-container>
      <div class="row">
        <ng-container *ngFor="let item of data; let i = index">
          <ng-container *ngIf="i > 0 && !noTableSplitting">
            <div
              class="offer-detail-accordion__data mb-2 break-word"
              [ngClass]="[
                columnWidths[i],
                fields[i] === 'price'
                  ? 'offer-detail-accordion__data-last-column'
                  : ''
              ]"
              *ngFor="let field of fields; index as i"
            >
              <span>
                {{ item[field] }}
              </span>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #isText>
      <div class="row">
        <div
          class="col-12 offer-detail-accordion__data offer-detail-accordion__prewrap pr-0 markdown"
          ngPreserveWhitespaces
          markdown
        >
          {{ data }}
        </div>
      </div>
    </ng-template>
  </div>
</li>
