import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { SiteConfiguration, SITES } from '../config/sites';
import { SiteSettingService } from '../services/site-setting.service';

@Directive({
  selector: '[appFeatureEnabled]'
})
export class FeatureEnabledDirective {
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<Element>,
    private viewContainer: ViewContainerRef,
    private siteSettingService: SiteSettingService
  ) {}

  @Input('appFeatureEnabled') set appFeatureEnabled(
    name: keyof SiteConfiguration['features']
  ) {
    const siteConfig = SITES[this.siteSettingService.siteSetting.site.name];

    if (siteConfig.features[name]) {
      if (!this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      }
    } else {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
