import { DropdownField } from './data.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import Offer from '../definitions/Offer.interface';
import Municipality from '../definitions/Municipality.interface';

export enum OfferState {
  Created = 'created',
  Draft = 'draft',
  ReadyForPublishing = 'ready_for_publishing',
  Published = 'published',
  Archived = 'archived'
}
export interface EditableOffer {
  id: string;
  state: OfferState;
  title: string;
  short_description: string;
  price: number;
  author_email: string;
  contact_email: string;
  cancellation_policy: string;
  region: DropdownField | [];
  place: DropdownField | [];
  institution: DropdownField | [];
  target_group: DropdownField | [];
  intensity: string;
  intensity_short: string;
  course_level: DropdownField | [];
  child_care: DropdownField | [];
  course_time: DropdownField | [];
  course_content: DropdownField | [];
  course_topic: DropdownField | [];
  workload: DropdownField | [];
  integration_goal: DropdownField | [];
  german_level_starting: DropdownField | [];
  additional_infos: DropdownField | [];
  department_name: string;
  offer_type_name: string;
  phone: string;
  registration: string;
  external_url: string;
  external_url_2: string;
  duration_detail: string;
  place_detail: string;
  price_detail: string;
  external_offer_url: string;
  external_offer_url_2: string;
  entry_time: string;
  work_assignment: DropdownField | [];
  industry: DropdownField | [];
  modules: DropdownField | [];
  internal_reference: string;
  contact_person: string;
  intensity_filter: DropdownField | [];
  municipalities: Municipality[];
}

export enum OfferSavingTechnique {
  OnlyUpdate = 'update',
  UpdateAndPublish = 'publish'
}

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  API_URL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getEdittableOffer(uid: string): Observable<EditableOffer> {
    return this.http.get<EditableOffer>(`${this.API_URL}/offers/${uid}/edit`);
  }

  getPreviewOfOffer(uid: string): Promise<Offer> {
    return this.http
      .get<Offer>(`${this.API_URL}/offers/${uid}/preview`)
      .toPromise();
  }

  updateEdittableOffer(
    editableOffer: EditableOffer,
    uid: string,
    offerSavingTechnique: OfferSavingTechnique
  ): Observable<EditableOffer> {
    return this.http.put<EditableOffer>(
      `${this.API_URL}/offers/${uid}/update?offerSavingTechnique=${offerSavingTechnique}`,
      editableOffer
    );
  }
}
