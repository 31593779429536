<ul class="footer-menu-social d-flex">
  <li class="mdl-social-media-links__item">
    <a href="https://facebook.com/kantonzuerich" target="_blank">
      <img alt="Facebook" src="../../../assets/icons/social/facebook.svg" />
    </a>
  </li>

  <li class="mdl-social-media-links__item">
    <a href="https://www.linkedin.com/company/kanton-zürich" target="_blank">
      <img alt="LinkedIn" src="../../../assets/icons/social/linkedIn.svg" />
    </a>
  </li>

  <li class="mdl-social-media-links__item">
    <a href="https://twitter.com/kantonzuerich" target="_blank">
      <img alt="Twitter" src="../../../assets/icons/social/twitter.svg" />
    </a>
  </li>

  <li class="mdl-social-media-links__item">
    <a href="https://www.youtube.com/ktzuerich" target="_blank">
      <img alt="YouTube" src="../../../assets/icons/social/youtube.svg" />
    </a>
  </li>
</ul>
