import { Component, Input } from '@angular/core';
import LinkItem from '../../definitions/LinkItem.interface';

@Component({
  selector: 'app-links-list',
  templateUrl: './links-list.component.html',
  styleUrls: ['./links-list.component.scss']
})
export class LinksListComponent {
  @Input() links: LinkItem[];
  @Input() newWindow = false;
  @Input() type: string;
  @Input() color = 'black';

  constructor() {}
}
