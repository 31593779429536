import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatomoInjector } from 'ngx-matomo';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    translate: TranslateService,
    private matomoInjector: MatomoInjector
  ) {
    translate.setDefaultLang('de');
    if (environment.production) {
      this.matomoInjector.init(environment.matmo.url, environment.matmo.siteId);
    }
  }
}
