import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import Offer from 'src/app/definitions/Offer.interface';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from '@angular/forms';
import { ErrorMessage, DataService } from 'src/app/services/data.service';
import { TranslateService } from '@ngx-translate/core';

export enum Gender {
  Male = 'male',
  Female = 'female',
  Other = 'other'
}

export enum ChildcareEnum {
  Yes = 'yes',
  No = 'no'
}

export interface SelectOption {
  text: string;
  value: any;
}

export interface OfferBooking {
  offer_id: number;
  gender: Gender;
  first_name: string;
  last_name: string;
  community: string;
  phone: string;
  email: string;
  client_abbreviation: string;
  message: string;
  more_info: boolean;
  callback: boolean;
  register_other_person: boolean;
}

@Component({
  selector: 'app-offer-booking-body',
  templateUrl: './offer-booking-body.component.html',
  styleUrls: ['./offer-booking-body.component.scss']
})
export class OfferBookingBodyComponent implements OnInit {
  @Input() offer: Offer;
  bookingFormGroup: FormGroup;
  backendErrors: ErrorMessage;
  offerBooking: OfferBooking;
  showErrors = false;
  currentYear: number;

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.initForm();
  }

  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private router: Router,
    private translationService: TranslateService,
    public dataService: DataService
  ) {}

  submit(): void {
    this.backendErrors = null;
    this.bookingFormGroup.markAllAsTouched();

    if (this.bookingFormGroup.invalid) {
      this.showErrors = true;
      return;
    }
    this.offerBooking = this.bookingFormGroup.value;
    this.offerBooking.offer_id = this.offer.id;
    this.dataService.bookOffer(this.offerBooking).then(
      () => {
        this.router.navigate(['/home']);
      },
      (error) => {
        this.showErrors = true;
        this.backendErrors = error.error as ErrorMessage;
      }
    );
  }

  hasError(attribute: string): boolean {
    return this.utilService.hasError(attribute, this.bookingFormGroup);
  }

  errorFor(attribute: string): string {
    return this.utilService.errorFor(attribute, this.bookingFormGroup);
  }

  private initForm(): void {
    this.bookingFormGroup = this.formBuilder.group({
      more_info: [false],
      callback: [false],
      register_other_person: [false],
      message: [''],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      community: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      client_abbreviation: ['', [Validators.required]],
      recaptchaReactive: new FormControl(null, Validators.required)
    });
  }
}
