export type SiteOptions = typeof DEFAULT_OPTIONS;

export const DEFAULT_OPTIONS = {
  OFFERS_LIST: {
    // This array configures which fields are displayed in offers-list-item
    // and offers-list-sort. Fields name must be same as of Offer interface
    // and order is maintained by position in this array.
    ALLOWED_COLUMNS: [
      'offer_type_name',
      'institution',
      'title',
      'internal_reference',
      'municipalities_short',
      'price_formatted'
    ],
    ALLOWED_COLUMNS_MOBILE: [
      'title',
      'institution',
      'place',
      'price_formatted'
    ],
    // How columns should be responsive. It applies for columns on same
    // index in array above. Values are supposed to be BS grid classes.
    // there needs to be one col left (so sum needs to be 11), since
    // at the end there is also a column with "show detial" link
    COL_WIDTHS: ['col-2', 'col-2', 'col-3', 'col-2', 'col-2', 'col-1'],
    SORTABLE_COLUMNS: [
      'offer_type_name',
      'institution',
      'title',
      'internal_reference',
      'municipalities_short',
      'duration_detail',
      'intensity_short'
    ],
    // in case we have a helper attribute that we display on the view but we cant use it to sort
    SORTABILITY_MAPPING: {
      price_formatted: 'price'
    }
  },
  OFFER_DETAIL: {
    // This array configures which fields are displayed in offer detail page.
    // Fields name must be same as of Offer interface and order is maintained by position in this array.
    ALLOWED_ATTRIBUTES: [
      'short_description',
      'place_detail',
      'municipalities_short',
      'target_group',
      'course_level',
      'child_care',
      'course_time',
      'course_content',
      'integration_goal',
      'course_topic',
      'industry',
      'modules',
      'workload',
      'german_level_starting',
      'work_assignment',
      'intensity',
      'duration_detail',
      'entry_time',
      'price_detail',
      'cancellation_policy',
      'internal_reference',
      'external_url',
      'external_offer_url',
      'phone',
      'contact_email',
      'registrations'
    ]
  },
  OFFER_DETAIL_ACCORDION: {
    ALLOWED_COLUMNS: ['description', 'duration', 'unit', 'price'],
    ALLOWED_COLUMNS_OTHER: ['description_other', 'duration', 'unit', 'price'],
    COL_WIDTHS: ['col-5', 'col-3', 'col-2', 'col-2']
  }
};
