<app-page-header color="dark-blue">
  <app-top-bar [compact]="true"></app-top-bar>
  <!-- TODO: subtitle is placeholder, replace with correct attribute of offer -->
  <app-hero
    view="detail"
    title="{{ 'OFFER_BOOKING.PERSONAL_DATA' | translate }}"
    subtitle="{{ 'OFFER_BOOKING.FILL_FORM' | translate }}"
    subtitleColor="turquoise"
  ></app-hero>
</app-page-header>

<app-page-content>
  <div class="container mb-4">
    <ng-container *ngIf="isLoading; else bookingTemplate">
      <div class="offers-list__spinner list-group-item text-center">
        <div class="spinner-border text-dark" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </ng-container>
    <ng-template #bookingTemplate>
      <app-offer-booking-body [offer]="offer"></app-offer-booking-body>
    </ng-template>
  </div>
</app-page-content>

<app-footer></app-footer>
