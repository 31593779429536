<div class="filter-selection">
  <ng-container>
    <div
      *ngFor="let item of selected"
      class="filter-selection__item btn btn-darkgray mb-2"
    >
      <div class="filter-selection__label pr-3">
        {{ item.name }}
      </div>
      <div
        class="filter-selection__remove"
        (click)="remove(item)"
        *ngIf="!readOnly"
      ></div>
    </div>
    <button
      *ngIf="selected && selected.length > 0"
      class="filter-selection__reset btn btn-reset mb-2"
      (click)="clear()"
    >
      <span> Zurücksetzen </span>
      <svg>
        <use xlink:href="#undo">
          <svg id="undo" viewBox="0 0 16 16">
            <path
              d="M5.926 6.3L2.657 9.28 0 5.616l1.235-.82.888 1.086a7.122 7.122 0 012.5-3.968A7.24 7.24 0 019.066.333C12.938.333 16 3.632 16 7.465c.007.904-.167 1.8-.513 2.637a6.808 6.808 0 01-1.501 2.236 6.883 6.883 0 01-2.258 1.487 6.936 6.936 0 01-2.661.508 7.216 7.216 0 01-5.055-2.04l1.132-1.12a5.62 5.62 0 003.923 1.575 5.32 5.32 0 002.05-.387 5.279 5.279 0 001.738-1.144 5.221 5.221 0 001.154-1.721 5.184 5.184 0 00.39-2.031c0-2.96-2.343-5.547-5.332-5.547a5.627 5.627 0 00-3.422 1.216A5.536 5.536 0 003.7 6.176l1.143-1.042 1.083 1.167z"
            ></path>
          </svg>
        </use>
      </svg>
    </button>
  </ng-container>
</div>
